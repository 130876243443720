body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app {
  text-align: center;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  z-index: 1000;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
}

.nav ul li {
  cursor: pointer;
  transition: color 0.3s;
}

.nav ul li:hover {
  color: #f0a500;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url('./images/intro-bg.jpg') no-repeat center center;
  background-size: cover;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.introduction {
  padding: 50px 20px;
  background: #f9f9f9; /* Vaalea tausta esittelylle */
  text-align: center;
}

.introduction h2 {
  margin-bottom: 20px;
}

.introduction p {
  max-width: 800px; /* Rajaa tekstin leveyttä */
  margin: 0 auto; /* Keskittää tekstin */
  line-height: 1.6; /* Parantaa luettavuutta */
  font-size: 1.1rem; /* Lisää hieman kokoa */
  color: #333; /* Tekstin väri */
}

.services {
  padding: 40px 20px;
}

.services h2 {
  margin-bottom: 20px;
}

.service-list {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.service-item {
  text-align: center;
  max-width: 200px;
}

.service-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #f0a500;
  transition: transform 0.3s ease;
}

.service-icon:hover {
  transform: scale(1.1);
}

.contact {
  padding: 50px 20px;
  background: #333;
  color: white;
  text-align: center; /* Keskittää otsikon ja sisällön */
}

.contact h2 {
  margin-bottom: 30px; /* Lisää tilaa otsikon ja listan väliin */
}

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0 auto; /* Keskittää listan */
  display: inline-block; /* Tekee listasta keskitetyn */
  text-align: left; /* Teksti pysyy vasemmalla linjassa */
}

.contact-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.contact-list a {
  color: #f0a500; /* Oranssi väri */
  text-decoration: none; /* Poistaa alleviivauksen */
  font-weight: bold;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.contact-list a:hover {
  color: white; /* Valkea hover-väri */
  text-decoration: underline; /* Alleviivaus hoverilla */
}

.contact-icon {
  color: #f0a500; /* Ikonin väri */
  font-size: 20px;
}